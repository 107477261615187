/* 救治单元注册 */
<template>

  <div class="register wrapper">
    <div  class="register-tabs center-float-center mb-20">
      <el-radio-group v-model="activeName" @change="changeRegister">
        <el-radio label="first">胸痛中心注册</el-radio>
        <el-radio label="second">救治单元注册</el-radio>
      </el-radio-group>
    </div>
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="240px"
      class="demo-ruleForm"
      status-icon
      style="width: 60%; margin: 0 auto;"
    >
      <div class=" pb-30 pl-30 ml-30" style="color: #fe733d">注意:当前是救治单元注册，如二级及二级以上医院建设请前往胸痛中心注册！<router-link to="/register/pectoralgia">立即前往</router-link></div>
      <div class="t-red pb-30 pl-30 ml-30">
        <el-alert :closable="false" type="warning">
          <div class="f14">提示：注册申请提交后，需等待管理人员审核，无法登录湖南省胸痛填报平台；可登录当前门户网站，登录成功后，点击首页<span class="t-cu pl-5 pr-5">机构认证</span>查看审核状态。
            <template v-if="!organId"><router-link to="/login">已注册，去登录</router-link></template>
            <template v-if="organId"><br />已注册<router-link class="pl-10" to="/auth/organ">点击查看审核状态</router-link></template>
          </div>
        </el-alert>
      </div>
      <el-form-item label="执业许可证机构名称：" prop="name">
        <el-input v-model="ruleForm.name" placeholder="执业许可证上的机构名称"/>
      </el-form-item>
      <el-form-item label="执业许可证登记号：" prop="licenseNo">
        <el-input v-model="ruleForm.licenseNo" placeholder="执业许可证登记号"/>
      </el-form-item>
      <el-form-item label="执业许可证过期日期："  prop="expiredDate">
        <el-date-picker
          v-model="ruleForm.expiredDate"
          type="date"
          label="执业许可证过期日期"
          placeholder="执业许可证过期日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          style="width: 100%"
        />
        <el-checkbox v-model="ruleForm.expiration" @change="$refs.ruleFormRef.validateField('expiredDate', () => null)">无有效期</el-checkbox>
      </el-form-item>
      <el-form-item label="上传许可证正面照片：" prop="licenseFrontPath">
        <el-upload
            ref="upload"
            class="upload-demo"
            :action="basicUrl+'/api/dmz/ORGAN/file/upload'"
            :limit="1"
            :file-list="licenseFrontFileList"
            :on-success="handleExceed01"
            :before-upload="beforeAvatarUpload01"
            list-type="picture"
        >
          <template #trigger>
            <el-button type="primary" style="background-color: #1c69a2">选择文件</el-button>
          </template>
        </el-upload>
      </el-form-item>
<!--      <el-form-item label="上传承诺函：" prop="promisePath">-->
<!--        <el-upload-->
<!--            ref="upload"-->
<!--            accept=".pdf, .jpg, .jpeg, .png"-->
<!--            class="upload-demo"-->
<!--            :action="basicUrl+'/api/dmz/ORGAN/file/upload'"-->
<!--            :limit="1"-->
<!--            :file-list="promiseFileList"-->
<!--            :on-success="handleExceed02"-->
<!--            :before-upload="beforeAvatarUpload02"-->
<!--        >-->
<!--          <template #trigger>-->
<!--            <el-button type="primary" style="background-color: #1c69a2">选择文件</el-button>-->
<!--          </template>-->
<!--        </el-upload>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="上传任命书：" prop="appointPath">-->
<!--        <el-upload-->
<!--            ref="upload"-->
<!--            accept=".pdf, .jpg, .jpeg, .png"-->
<!--            class="upload-demo"-->
<!--            :action="basicUrl+'/api/dmz/ORGAN/file/upload'"-->
<!--            :limit="1"-->
<!--            :file-list="appointFileList"-->
<!--            :on-success="handleExceed03"-->
<!--            :before-upload="beforeAvatarUpload03"-->
<!--        >-->
<!--          <template #trigger>-->
<!--            <el-button type="primary" style="background-color: #1c69a2">选择文件</el-button>-->
<!--          </template>-->
<!--        </el-upload>-->
<!--      </el-form-item>-->
      <el-form-item label="省      份：" prop="province">
        <el-select v-model="ruleForm.province" placeholder="未选择" @change="searchCity">
          <el-option v-for="item in provinceList" :label="item.name" :value="item.id" :key="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="城      市：" prop="city">
        <el-select v-model="ruleForm.city" placeholder="未选择" @change="searchCounty">
          <el-option v-for="item in cityList" :label="item.name" :value="item.id" :key="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="区      县：" prop="districtId">
        <el-select v-model="ruleForm.districtId" placeholder="未选择">
          <el-option v-for="item in countyList" :label="item.name" :value="item.id" :key="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="地      址：" prop="address">
        <el-input v-model="ruleForm.address" placeholder="地      址"/>
      </el-form-item>
      <el-form-item label="等      级：" prop="level">
        <el-select v-model="ruleForm.level" placeholder="未选择">
          <el-option v-for="item in levels" :label="item.desc" :value="item.name" :key="item.name" />
        </el-select>
      </el-form-item>
      <el-form-item :label="p.label + '：'" :prop="index === 0 ? 'parentOrgans' : ''"  v-for="(p, index) in parentOrgans" :key="'parent' + index">
        <el-select
          class="mr-10"
          v-model="p.id"
          filterable
          remote
          reserve-keyword
          remote-show-suffix
          :remote-method="remoteMethod"
          :loading="loadingOrgan"
          @change="(val) => selectOrgan(val, index)"
          placeholder="未选择"
          clearable
        >
          <el-option v-for="item in organs" :label="item.name" :value="item.id" :key="item.id" />
        </el-select>
        <el-button @click="addOrgan" v-if="index === 0 && parentOrgans.length === 1">添加更多+</el-button>
        <el-button @click="delOrgan(index)" v-if="index === 0 && parentOrgans.length === 3">取消添加-</el-button>
      </el-form-item>
      <el-form-item label="再灌注策略：" prop="measure">
        <el-checkbox-group v-model="ruleForm.measure">
          <el-checkbox label="02">溶栓</el-checkbox>
          <el-checkbox label="08">转运溶栓</el-checkbox>
          <el-checkbox label="07">转运PCI</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="负责人姓名：" prop="leaderName">
        <el-input v-model="ruleForm.leaderName" placeholder="负责人姓名"/>
      </el-form-item>
      <el-form-item label="负责人职务：" prop="leaderPost">
        <el-input v-model="ruleForm.leaderPost" placeholder="负责人职务"/>
      </el-form-item>
      <el-form-item label="负责人邮箱：" prop="leaderEmail">
        <el-input v-model="ruleForm.leaderEmail" placeholder="负责人邮箱"/>
      </el-form-item>
      <el-form-item label="负责人手机号码：" prop="leaderPhone">
        <el-input v-model="ruleForm.leaderPhone" placeholder="负责人手机号码"/>
      </el-form-item>
      <el-divider border-style="dashed" />
      <el-form-item label="联络员姓名：" prop="linkmanName">
        <el-input v-model="ruleForm.linkmanName" placeholder="联络员姓名"/>
      </el-form-item>
      <el-form-item label="联络员职务：" prop="linkmanPost">
        <el-input v-model="ruleForm.linkmanPost" placeholder="联络员职务"/>
      </el-form-item>
      <el-form-item label="联络员手机：" prop="linkmanPhone">
        <el-input v-model="ruleForm.linkmanPhone" placeholder="联络员手机"/>
      </el-form-item>
      <el-form-item label="联络员邮箱：" prop="linkmanEmail">
        <el-input v-model="ruleForm.linkmanEmail" placeholder="联络员邮箱"/>
      </el-form-item>
      <el-form-item label="登录账号：" prop="loginName">
        <el-input v-model="ruleForm.loginName" placeholder="登录账号" autocomplete="off"/>
      </el-form-item>
      <el-form-item label="密      码：" prop="pwd">
        <el-input v-model="ruleForm.pwd" type="password" placeholder="密码（格式:字母+数字组合）" autocomplete="off" />
      </el-form-item>
      <el-form-item label="确认密码：" prop="checkPass">
        <el-input v-model="ruleForm.checkPass" type="password" placeholder="确认密码（格式:字母+数字组合）" autocomplete="off" />
      </el-form-item>
      <el-form-item label="" prop="agree">
        <el-checkbox  v-model="ruleForm.agree" label="我已认真阅读并同意" />
        <router-link to="/policy" tag="a" target="_blank" class="pl-30">使用条款和隐私政策</router-link>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleFormRef')" size="large" :loading="loading" :disabled="!ruleForm.agree" style="background-color: #1c69a2">注册并申请关联</el-button>
        <el-button @click="resetForm('ruleFormRef')" size="large">重置</el-button>
        <router-link to="/login" class="pl-30">已有账号，点击登录</router-link>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getDistrict, getOrganizationLevel, postOrganizationRegister, getOrganizationList } from '@/api/index'

export default{
  name: "RegisterUnit",
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请填写密码'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleFormRef.validateField('checkPass', () => null)
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次填写密码'))
      } else if (value !== this.ruleForm.pwd) {
        callback(new Error("两次密码不一样!"))
      } else {
        callback()
      }
    }
    const validateExpired = (rule, value, callback) => {
      if (!value && !this.ruleForm.expiration) {
        callback(new Error('请填写执业许可证过期日期'))
      }else {
        callback()
      }
    }
    //手机号验证
    const validatePhone1=(rule, value, callback) =>{
      if(!(/^1[3456789]\d{9}$/.test(value)) && this.ruleForm.leaderPhone){
        callback(new Error('手机号码不合法，请重新输入'))
      }else {
        callback()
      }
    }
    const validatePhone2=(rule, value, callback) =>{
      console.log(/^1[3456789]\d{9}$/.test(value),222)
      if(!(/^1[3456789]\d{9}$/.test(value)) && this.ruleForm.linkmanPhone ){
        callback(new Error('手机号码不合法，请重新输入'))
      }else {
        callback()
      }
    }
    //邮箱验证
    const validateEmail1=(rule, value, callback) =>{
      var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if(!(emailRegex.test(value)) && this.ruleForm.leaderEmail ){
        callback(new Error('邮箱不合法，请重新输入'))
      }else {
        callback()
      }
    }
    const validateEmail2=(rule, value, callback) =>{
      var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if(!(emailRegex.test(value)) && this.ruleForm.linkmanEmail ){
        callback(new Error('邮箱不合法，请重新输入'))
      }else {
        callback()
      }
    }
    return {
      basicUrl : this.$basicUrl,
      activeName: 'second',
      loading: false,
      ruleForm: {
        type: 'RESCUE', // THORACALGIA("胸痛中心注册"),RESCUE("救治单元注册")
        province: '430000',
        districtId: '',
        agree: false,
        measure: []
      },
      rules: {
        name: [{ required: true, message: '请填写执业许可证上的机构名称', trigger: 'blur' }],
        licenseNo: [{ required: true, message: '请填写执业许可证登记号', trigger: 'blur' }],
        expiredDate: [{ required: true, validator: validateExpired, trigger: 'blur' }],
        licenseFrontPath: [{ required: true, message: '请上传许可证正面照片', trigger: 'blur' }],
        promisePath: [{ required: false, message: '请上传承诺函照片', trigger: 'blur' }],
        appointPath: [{ required: false, message: '请上传任命书照片', trigger: 'blur' }],
        address: [{ required: true, message: '请填写地址', trigger: 'blur' }],
        leaderName: [{ required: true, message: '请填写认证负责人姓名', trigger: 'blur' }],
        leaderPost: [{ required: true, message: '请填写认证负责人职务', trigger: 'blur' }],
        leaderEmail: [{ required: true,validator:validateEmail1, message: '请填写认证负责人正确邮箱', trigger: 'blur' }],
        leaderPhone: [{ required: true, validator:validatePhone1,message: '请填写认证负责人正确手机号码', trigger: 'blur' }],
        linkmanName: [{ required: true, message: '请填写联络员姓名', trigger: 'blur' }],
        linkmanPost: [{ required: true, message: '请填写联络员职务', trigger: 'blur' }],
        linkmanEmail: [{ required: true,validator:validateEmail2, message: '请填写联络员正确邮箱', trigger: 'blur' }],
        linkmanPhone: [{ required: true, validator:validatePhone2, message: '请填写联络员正确手机号码',trigger: 'blur' }],
        loginName: [{ required: true, message: '请填写登录账号', trigger: 'blur' }],
        province: [{ required: true, message: '请选择省份', trigger: 'change'}],
        districtId: [{ required: true, message: '请选择区县', trigger: 'change'}],
        city: [{ required: true, message: '请选择城市', trigger: 'change'}],
        level: [{ required: true, message: '请选择等级', trigger: 'change'}],
        measure: [{ required: true, message: '请选择再灌注策略', trigger: 'change'}],
        parentOrgans: [{ required: true, message: '请选择上级转诊医院', trigger: 'change'}],
        pwd: [
          { required: true, validator: validatePass, trigger: 'blur'}
        ],
        checkPass: [{ required: true, validator: validatePass2, trigger: 'blur' }],
      },
      parentOrgans: [{ id: '', label: '上级转诊医院一'}],
      organs: [],
      loadingOrgan: false,
      provinceList: [],
      cityList: [],
      countyList: [],
      levels: [],
      licenseFrontFileList: [],
      promiseFileList:[],
      appointFileList:[],
      organId: ''
    }
  },
  mounted() {
    if (JSON.parse(localStorage.getItem('cpis-web_userInfo'))) {
      this.organId = JSON.parse(localStorage.getItem('cpis-web_userInfo')).id
    }
    this.reqDistrict('', 'PROVINCE')
    this.getOrganLevel()
    this.getOrgans('')
  },
  methods: {
    changeRegister(val) {
      if(val === 'first') {
        this.$router.push('/register/pectoralgia')
      } else {
        this.$router.push('/register/unit')
      }
    },
    reqSubmit() {
      postOrganizationRegister(this.ruleForm).then((res) => {
        if(res.msg.flag === 0) {
          this.$message.success(res.msg.message)
          this.resetForm('ruleFormRef')
        } else {
          this.$message.error(res.msg.message)
        }
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
    },
    async submitForm(formEl) {
      await this.$refs[formEl].validate((valid, fields) => {
        if (valid) {
          console.log('submit!')
          this.reqSubmit()
        } else {
          console.log('error submit!', fields)
        }
      })
    },
    resetForm(formEl) {
      if (!formEl) return
      this.$refs[formEl].resetFields()
    },
    async getOrgans (val) {
      this.loadingOrgan = true
      const res = await getOrganizationList({keyword: val})
      this.organs = res.biz
      this.loadingOrgan = false
    },
    remoteMethod(val) {
      if (val) {
        this.getOrgans(val)
      }
    },
    addOrgan() {
      this.parentOrgans.push({ id: '', label: '上级转诊医院二'})
      this.parentOrgans.push({ id: '', label: '上级转诊医院三'})
    },
    delOrgan() {
      this.parentOrgans.splice(1, 2)
    },
    selectOrgan(val, index) {
      this.parentOrgans[index].id = val
      this.ruleForm.parentOrgans = []
      this.parentOrgans.map((item, index) => {
        if (index === 0 && !item.id) {
          return
        }
        if (item.id) {
          this.ruleForm.parentOrgans.push(item.id)
        }
      })
    },
    async getOrganLevel() {
      const res = await getOrganizationLevel()
      this.levels = res.biz
    },
    async reqDistrict(parentCode, level) {
      const res = await getDistrict({parentCode, level: level})
      if (level === 'PROVINCE') {
        this.provinceList = res.biz
        this.cityList = []
        this.countyList = []
        if(this.ruleForm.province!=null && this.ruleForm.province!=''){
          this.searchCity(this.ruleForm.province)
        }
      }
      if (level === 'CITY') {
        this.cityList = res.biz
        this.countyList = []
      }
      if (level === 'COUNTY') {
        this.countyList = res.biz
      }

    },
    searchCity(val) {
      this.reqDistrict(val, 'CITY')
      this.ruleForm.districtId = ''
    },
    searchCounty(val) {
      this.reqDistrict(val, 'COUNTY')
      this.ruleForm.districtId = ''
    },
    handleExceed01(res, index) {
      console.log(res, index)
      if(res && res.msg.flag === 0) {
        this.ruleForm.licenseFrontPath = res.biz.path;
        this.licenseFrontFileList = [{name: res.biz.name, url: res.biz.url}]
      }
    },
    beforeAvatarUpload01(rawFile) {
      console.log(rawFile)
      if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png') {
        this.$message.error('请上传jpg或者png格式的图片')
        return false
      } else if (rawFile.size / 1024 / 1024 > 2) {
        this.$message.error('图片文件大小不超过2MB!')
        return false
      }
      return true
    },
    // handleExceed02(res, index) {
    //   console.log(res, index)
    //   if(res && res.msg.flag === 0) {
    //     this.ruleForm.promisePath = res.biz.path
    //     this.promiseFileList=[{name: res.biz.name, url: res.biz.url}]
    //   }
    // },
    // beforeAvatarUpload02(rawFile) {
    //   console.log(rawFile)
    //   if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png' && rawFile.type !== 'application/pdf') {
    //     this.$message.error('请上传jpg或者png或者pdf格式的图片')
    //     return false
    //   } else if (rawFile.size / 1024 / 1024 > 2) {
    //     this.$message.error('文件大小不超过2MB!')
    //     return false
    //   }
    //   return true
    // },
    // handleExceed03(res, index) {
    //   console.log(res, index)
    //   if(res && res.msg.flag === 0) {
    //     this.ruleForm.appointPath = res.biz.path;
    //     this.appointFileList=[{name: res.biz.name, url: res.biz.url}]
    //   }
    // },
    // beforeAvatarUpload03(rawFile) {
    //   console.log(rawFile)
    //   if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png' && rawFile.type !== 'application/pdf') {
    //     this.$message.error('请上传jpg或者png或者pdf格式的图片')
    //     return false
    //   } else if (rawFile.size / 1024 / 1024 > 2) {
    //     this.$message.error('文件大小不超过2MB!')
    //     return false
    //   }
    //   return true
    // }
  }
}
</script>
<style lang="scss">
.register-tabs{
  background: #2790ca;
  border-radius: 10px;
  .el-radio{
    height: 52px;
    line-height: 42px;
    padding: 0 20px;
    .el-radio__label{
      font-size: 21px;
      font-weight: bold;
      letter-spacing: 2px;
      color: #fff;
    }
    .el-radio__inner{
      width: 20px;
      height: 20px;
      border-width: 2px;
    }
    .el-radio__input{
      line-height: normal;
    }
  }
  .el-radio.is-checked{
    border-radius: 30px;
    box-shadow: 0 0 6px 3px #1b4773 inset;
    background: linear-gradient(90deg, #205189, #205589);
    .el-radio__inner::after{
      width: 11px;
      height: 11px;
      background: #01c5ff;
    }
    .el-radio__inner::before{
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 16px;
      height: 16px;
      transform: translate(-50%,-50%) scale(1);
      background: #fff;
      border-radius: 50%;
    }
  }
}
</style>

